import React from 'react';
import "../css/Error.css";
import { Link } from "react-router-dom";
import ErrorImg from "../img/error.gif";
import { MetaTags } from 'react-meta-tags';

const Error = () => {
  return (
    <>
        {/* ========= Sco ========== */}
        <MetaTags>    
        <title>Mediline Health Care | Error Page</title>
        <meta
          name="description"
          content="Discover top-quality healthcare at Mediline Health Care. While our website undergoes maintenance, trust us to provide you with exceptional medical services once we're back online. Your health is our priority"
        />
        <meta
          name="google-site-verification"
          content="RsNmRSAUj8OUrThZ6RdzMd-KQnGfm_lGA-pXGuYhaqM"
        />
        <meta
          property="og:title"
          content="Mediline Health Care | Error Page"
        />
        <meta
          property="og:description"
          content="Discover top-quality healthcare at Mediline Health Care. While our website undergoes maintenance, trust us to provide you with exceptional medical services once we're back online. Your health is our priority"
        />
        <meta name="keywords" content="Mediline Health Care | Error Page" />
        <link rel="canonical" href="https://medilinehomecare.in/" />
      </MetaTags>
      {/* ======== Sco end ======= */}
       {/* ============= Error Page ========== */}
       <div className="ErrorPage">
        <div className="row">
          <div className="col-md-6">
              <div class="ErrorImg">
                <img
                  class="left-image"
                  src={ErrorImg}
                  alt="Error Gif"
                />
              </div>
          </div>
          <div className="col-md-6">
              <div class="Errorcontent">
                <div class="error-code">
                  <h2>404</h2>
                </div>
                <div class="error-message">
                <h4>Oops! Page not found.</h4>
                <p>We regret any inconvenience caused. Our dedicated team is actively addressing this issue. Kindly revisit shortly to access our healthcare services or click the 'Back to Home' button above to return to our homepage.</p>
                </div>
                <div class="back-to-home">
                  <Link to="/">Back to Home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Error;
