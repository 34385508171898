import React, { useState } from "react";
import "../css/Service.css";
import ServiceData from "../component/ServiceData";
import Footer from "../component/Footer";
import Bounce from "react-reveal/Bounce";
import Button from "react-bootstrap/Button";
import Enquiry from "../component/Enquiry";
import Zoom from "react-reveal/Zoom";
import { MetaTags } from "react-meta-tags";
import NavBar from "../component/NavBar";

const Service = () => {
  // =========================== Service View ====================
  const [modalShow, setModalShow] = useState(false);
  const [serviceId, setServiceId] = useState();

  const [finalData, setFinalData] = useState([]);

  const serviceView = (id, condition) => {
    setModalShow(condition);
    setServiceId(id);

    if (id !== "") {
      console.log(id);
      setFinalData(ServiceData.find((data) => data.id === id));
    }
  };

  return (
    <>
      <MetaTags>
        <title>
        Our Gallery | Mediline Health Care
        </title>
        <meta
          name="description"
          content="Immerse yourself in the world of Mediline Health Care through our captivating gallery. Discover our exceptional facilities and compassionate care."
        />
        <meta
          name="google-site-verification"
          content="RsNmRSAUj8OUrThZ6RdzMd-KQnGfm_lGA-pXGuYhaqM"
        />
        <meta
          property="og:title"
          content="Our Gallery | Mediline Health Care"
        />
        <meta
          property="og:description"
          content="Immerse yourself in the world of Mediline Health Care through our captivating gallery. Discover our exceptional facilities and compassionate care."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://medilinehomecare.in/gallery" />
      </MetaTags>
      {/* ======== Sco end ======= */}

      {/* ========== NavBar ========= */}
      <NavBar />
      {/* ========== ServiceSection1 ========== */}
      <div className="Service-Section1">
        <div className="serviceBanner">
          <div className="serviceAnimation">
            <h1>
              Mediline services that best suit <br />
              your needs – our hi-tech at home care
            </h1>
          </div>
        </div>
      </div>
      {/* ============ ServiceSection2 ========== */}
      <div className="Section2 mt-5" id="Services">
        <div className="container">
          <div className="heading-center mb-5">
            <h2> Our SERVICES DETAILS</h2>
            <Zoom right>
              <p>
                Explore Mediline services that best suit your needs – our
                hi-tech at home care, our modern in-clinic facilities or perhaps
                our varied hospital related support features.
              </p>
            </Zoom>{" "}
          </div>
          <div className="row">
            {ServiceData.map((item, index) => {
              const { id, ServiceImage, Header, ShortDesc } = item;
              return (
                <>
                  <div className="col-md-4 mb-4">
                    <div className="serviceCard">
                      <div className="Services">
                        <div className="serviceImage">
                          <img src={ServiceImage} alt="home nursing care" />
                        </div>
                        <div className="serviceTitle">
                          <h3>{Header}</h3>
                        </div>
                        <div className="serviceText">
                          <p>{ShortDesc}</p>
                        </div>
                        <div className="aboutButton">
                          <Button
                            variant="enquiry_btn"
                            onClick={() => setModalShow(true)}
                          >
                            Enquiry Now
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="enquiry mt-2">
        <div className="container">
          <Zoom>
            <h6>
              YOUR FIRST STEP TOWARDS EXPERIENCING ADVANCED MEDICINE STARTS
              HERE:
            </h6>
          </Zoom>
          <Bounce>
            <Button variant="enquiry_btn" onClick={() => setModalShow(true)}>
              Enquiry Now
            </Button>
          </Bounce>

          <Enquiry show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Service;
