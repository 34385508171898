import React from 'react';
import "../css/WhatsappBtn.css";
import { IoLogoWhatsapp } from 'react-icons/io5';

const WhatsappBtn = () => {
    return (
        <>
            <div className='WhatsBtn-con'>
                <div className='WhatsBtn'><a href="https://wa.me/+918454845438?text=" target='blank'>
                    
                        <IoLogoWhatsapp/>
                    </a>

                </div>
            </div>
        </>
    )
}

export default WhatsappBtn;