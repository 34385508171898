import React from 'react';
import "../css/Footer.css";
import logo from '../img/logo.png'
import { Link } from 'react-router-dom';
import {MdAddIcCall} from 'react-icons/md';
import {GoMail} from 'react-icons/go';
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrGoogle } from 'react-icons/gr';



const Footer = () => {
  return (
    <>
      <div className='footer pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 border-end border-bottom'>
              <div className="address_main">
                <div class="footer-logo">
                  <a href="index.jsx">
                    <img src={logo} class="img-responsive" alt="footer_logo" /></a>
                </div>
                <p>Mediline health care  is a medical hub available at doorstep visit  24x7. It is helpful for patients who find it difficult to reach the hospital and also saves time. We intend to bring all customers on a single platform with a seamless health care experience.
                </p>
                <div className="footer-icon">
                  <ul>
                    <li><i><MdAddIcCall /></i><span>Call us : </span> <a href="tel:84548 45438">+91 84548 45438</a></li>
                    <li><i><GoMail /></i><span>Email :</span><a href="mailto:medilinehomecare@gmail.com">medilinehomecare@gmail.com</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-8 border-bottom'>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='footer-head'>
                    <h3>SERVICES</h3>
                  </div>
                  <div className='footer-service'>
                    <ul>
                    <li><a href="/lab-test-home"><Link to="/lab-test-home">Dignostic</Link></a></li>
                    <li><a href="/home-visiting-doctors"><Link to="/home-visiting-doctors">Home Visit</Link></a></li>
                    <li><a href="/home-visit-doctor"><Link to="/home-visit-doctor">Procedure At Home</Link></a></li>
                    <li><a href="/medical-equipments"><Link to="/medical-equipments">Pharmacy with Mediline</Link></a></li>
                    <li><a href="/emergency-ambulance-service"><Link to="/emergency-ambulance-service">Ambulance Service</Link></a></li>
                    
                    </ul>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='footer-head'>
                    <h3>USEFUL</h3>
                  </div>
                  <div className='footer-link'>
                    <ul>
                    <li><a href="/"><Link to="/">Home</Link></a></li>
                    <li><a href="/patient-caretaker-service"><Link to="/patient-caretaker-service">About</Link></a></li>
                    {/* <li><a href="#"><Link to="/Service">Services</Link></a></li> */}
                    <li><a href="/gallery"><Link to="/gallery">Gallery</Link></a></li>
                    <li><a href="/contact-us"><Link to="/contact-us">Contact</Link></a></li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-5'>
                  <div className='footer-head'>
                    <h3>ADDRESS</h3>
                  </div>
                  <div className='footer-add'>
                    <ul>
                      <li><a href="https://goo.gl/maps/wAwTvqXqrzpH9T2f7" target='blank'><p>Shop No:290, Ground Plus One, Sarvoday Nagar,
                        Goregaon check Naka , W.E.H. Goregaon (E), Mumbai-400065.</p></a></li>
                    </ul>
                  </div>
                  {/* <div className='footer-head'>
                    <h3>Social Link</h3>
                  </div> */}
                  <div className='footer-social'>
                    <ul>
                      <li><a href="https://www.facebook.com/medilinehealth" target='blank'><i><GrFacebookOption /></i></a></li>
                      <li><a href="https://www.instagram.com/medilinehomecare/" target='blank'><i><GrInstagram /></i></a></li>
                      {/* <li><a href="#"><i><GrLinkedinOption /></i></a></li> */}
                      <li><a href="https://www.google.com/search?q=Mediline+Health+Care&stick=H4sIAAAAAAAA_-NgU1I1qDBOSjVPMjdMtjA2TDUzsEyzMqhItExJNjK2TDJJMzVKSTOxXMQq4puakpmTmZeq4JGamFOSoeCcWJQKADxqieNAAAAA&hl=en-GB&mat=CSS1mJ4GMDFRElMBeenfiLmIINMtbDv6FI4a-1lqx2JpiXfsAdVgJqxJ9RV3kCm7ARWL12Nx62ze-zZQoE_41fnaE3aFa-hMLBFrMZVJIpa0pR5yAZLnu_ygIbR-0w&authuser=0#ip=1" target='blank'><i><GrGoogle /></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row p-3'>
            <div className='col-md-6'>
              <div className="copyright-text">
                <p>Copyright &copy; 2023, All Right Reserved <a href="index.html">
                  <strong>Mediline</strong></a></p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="footer-menu">
                <div className="copyright-text text-center">
                  <p>Developed by<a href="https://skdm.in/">
                    <strong> Shree Krishna Digital Marketing</strong></a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
