import BloodTest from '../img/service/bloodtest.png';
import BloodPresssure from '../img/service/bloodpressure.png';
import Diagnostic from '../img/service/diagnostic.png';
import Xray from '../img/service/xray.png';
import Dressing from '../img/service/injury.png';
import Ecg  from '../img/service/ecg.png';
import Pathology  from '../img/service/pathology.png';
import Respiratory  from '../img/service/lungs.png';
import Other  from '../img/service/other.png';


const ServiceData = [
    {
        id:1,
        ServiceImage:BloodTest,
        Header:" All Types Of Blood test",
        ShortDesc:"To bring ease in your life and assist you with nothing to everything, our professionally trained staff will help you enhance your health and life. Specially curated plans, diets, and based on your preferences, we will make an action plan for you to achieve good health within a short span of time.",
    },
    {
        id:2,
        ServiceImage:BloodPresssure,
        Header:"BP CheckUp",
        ShortDesc:"We are trained to assess and monitor general health and also measure blood pressure, heart rate etc. So, avail our nursing attendant/s and give your loved ones the care and emotional support they deserve while recovering from disease or illness.",

    },
    {
        id:3,
        ServiceImage:Pathology,
        Header:"Pathology Test",
        ShortDesc:"Pathology tests simulate what happens to your body when you suffer from a specific disease. Tests are done on the cells of the specific organ in question, and the results help doctors get more information about that particular disease.",

    },
    {
        id:4,
        ServiceImage:Diagnostic,
        Header:"Diagnostic Tests",
        ShortDesc:"Mediline understand diagnostic tests are essential in diagnosis of disease or illness and pay way for an effective treatment plan. Better. From blood, urine, blood sugar, stool, cholesterol, kidney profile etc., you can get all tests done at home itself.",

    },
    {
        id:5,
        ServiceImage:Xray,
        Header:"X-Ray At Home",
        ShortDesc:"X-rays at Home may now be performed At Homes. Being able to avoid traveling to a hospital or diagnostic facility is incredibly handy for elderly folks. You can now easily access X-rays  at your home in Mumbai.",
    },
    {
        id:6,
        ServiceImage:Dressing,
        Header:"Dressing",
        ShortDesc:"our in-home dressing care service offers personalized care as per individual needs and in accordance with surgeon’s recommendations and protocols.",

    },
    {
        id:7,
        ServiceImage:Respiratory,
        Header:"Respiratory Therapist",
        ShortDesc:"Mediline professionals provide clinical respiratory therapy services, respiratory equipment services and home medical equipment to patients in their homes. Our team of skilled respiratory care practitioners and delivery equipment technicians assess, deliver and set up equipment and train patients and caregivers to properly use the equipment.",

    },
    {
        id:8,
        ServiceImage:Ecg,
        Header:"ECG At Home",
        ShortDesc:"Advanced ECG services now delivered at your doorstep. Our High-end ECG services at home are carried out by highly trained technicians using fully automated portable ECG machines. This ECG at home service is very helpful for people with suspected or existing heart conditions and helps reduce the hassle of travel to a diagnostic centre if the health doesn’t permit.",

    },
    {
        id:9,
        ServiceImage:Other,
        Header:"Others",
        ShortDesc:"Home Doctor book is a practical medicine for every household. It is a book specifically designed for isolated clients and contains all the information required to diagnose and treat common diseases at home.",
        
    },
 
]

export default ServiceData;