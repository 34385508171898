import React from 'react';
import '../css/Gallery.css';
import Footer from '../component/Footer';
import Blood from '../img/gallery/bloodtest.jpg';
import Bptest from '../img/gallery/bptest.jpg';
import Diagnostic from '../img/gallery/diagnostic.jpg';
import ECG from '../img/gallery/ecg.jpg';
import XRAY from '../img/gallery/xray.jpeg';
import Dressup from '../img/gallery/dressup.jpg';
import Button from 'react-bootstrap/Button';
import Enquiry from '../component/Enquiry';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import { MetaTags } from "react-meta-tags";
import NavBar from '../component/NavBar';

const Gallery = () => {
    // popup Modal 
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
        {/* ========= Sco ========== */}
      <MetaTags>
        <title>
        Our Gallery | Mediline Health Care
        </title>
        <meta
          name="description"
          content="Immerse yourself in the world of Mediline Health Care through our captivating gallery. Discover our exceptional facilities and compassionate care."
        />
        <meta
          name="google-site-verification"
          content="RsNmRSAUj8OUrThZ6RdzMd-KQnGfm_lGA-pXGuYhaqM"
        />
        <meta
          property="og:title"
          content="Our Gallery | Mediline Health Care"
        />
        <meta
          property="og:description"
          content="Immerse yourself in the world of Mediline Health Care through our captivating gallery. Discover our exceptional facilities and compassionate care."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://medilinehomecare.in/gallery" />
      </MetaTags>
      {/* ======== Sco end ======= */}
           {/* ========== NavBar ========= */}
           <NavBar />
            {/* =======GallerySection1 ========== */}
            <div className="GallerySection1" >
                <div className='galleryBanner'>
                <h1 className='d-none'>Make Your Dream Smile A Reality Our Gallery | Mediline Health Care</h1>
                    {/* <div className="galleryAnimation">
                        <p>Reclaim Your Smile With Us !</p>
                        <a href="#">online Consultation</a>
                    </div> */}
                </div>
            </div>
            {/* ===== GallerySection2 ===== */}
            <div className='Gallery mt-5'>
                <div className='container'>
                    <div className='heading-center mb-5'>
                        <h2>OUR GALLERY</h2>
                        <Zoom right>
                        <p>Mediline health care  medical hub  health care Services Gallery.</p>
                        </Zoom>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="gallery-image">
                                <div className="img-box">
                                    <img src={Dressup} alt="home medical service" />
                                    <div className="transparent-box">
                                        <div className="caption">
                                            <p>Dressing</p>
                                            <p className="opacity-low">Injury Dressing At Home</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="gallery-image">
                                <div className="img-box">
                                    <img src={Blood} alt="Mediline Health Care" />
                                    <div className="transparent-box">
                                        <div className="caption">
                                            <p>Blood Test</p>
                                            <p className="opacity-low">All Blood Test At Home</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="gallery-image">
                                <div className="img-box">
                                    <img src={ECG} alt="Mediline Health Care" />
                                    <div className="transparent-box">
                                        <div className="caption">
                                            <p>ECG</p>
                                            <p className="opacity-low">ECG Home At Home</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="gallery-image">
                                <div className="img-box">
                                    <img src={XRAY} alt="Mediline Health Care" />
                                    <div className="transparent-box">
                                        <div className="caption">
                                            <p>X-Ray</p>
                                            <p className="opacity-low">X-Ray At Home</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="gallery-image">
                                <div className="img-box">
                                    <img src={Bptest} alt="Mediline Health Care" />
                                    <div className="transparent-box">
                                        <div className="caption">
                                            <p>Blood Pressure</p>
                                            <p className="opacity-low">BP CheckUp At Home</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="gallery-image">
                                <div className="img-box">
                                    <img src={Diagnostic} alt="Mediline Health Care" />
                                    <div className="transparent-box">
                                        <div className="caption">
                                            <p>Diagnostic</p>
                                            <p className="opacity-low">Diagnostic At Home</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='enquiry mt-2'>
                <div className='container'>
                    <Zoom>
                        <h6>YOUR FIRST STEP TOWARDS EXPERIENCING ADVANCED MEDICINE STARTS HERE:</h6>
                    </Zoom>
                    <Bounce>
                        <Button variant="enquiry_btn" onClick={() => setModalShow(true)}>
                            Enquiry Now
                        </Button>
                    </Bounce>
                    <Enquiry
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Gallery;
