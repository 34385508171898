import React, { useState, useEffect } from "react";
import "../css/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPhoneCall, FiClock } from "react-icons/fi";
import {
  GrFacebookOption,
  GrInstagram,
  GrLinkedinOption,
  GrGoogle,
} from "react-icons/gr";
import logo from "../img/logo.png";
import Button from "react-bootstrap/Button";
import Enquiry from "../component/Enquiry";

const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="nav-bar-component">
        <section className="header">
          <div className="container">
            <div className="row">
              <div className="col-5">
                <div className="Email_Support">
                  <div className="Email-logo">
                    <FiClock />
                  </div>
                  <div className="opentime">
                    <p>Opening Hours: Mon - Sun : 24/7 Available</p>
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="row">
                  <div className="col border-start">
                    <div className="header-map">
                      <a
                        href="https://wa.me/+918454845438?text="
                        target="blank"
                      >
                        Get Direction
                      </a>
                    </div>
                  </div>
                  <div className="col border-start">
                    <div className="header-whatsapp">
                      <div className="container">
                        <button
                          variant="Enquiry_btn1"
                          onClick={() => setModalShow(true)}
                        >
                          Request Appointment
                        </button>

                        <Enquiry
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                      {/* <Link to="/Contact">Request Appointment</Link> */}
                      {/* <a href="">Request Appointment</a> */}
                    </div>
                  </div>
                  <div className="col  border-start border-end">
                    <div className="headersocial-link">
                      <div className="social-heder">
                        <a href="https://www.facebook.com/medilinehealth">
                          <GrFacebookOption />
                        </a>
                        <a href="https://www.instagram.com/medilinehomecare/">
                          <GrInstagram />
                        </a>
                        {/* <a href="https://www.instagram.com">
                          <GrLinkedinOption />
                        </a> */}
                        <a href="https://www.google.com/search?q=Mediline+Health+Care&stick=H4sIAAAAAAAA_-NgU1I1qDBOSjVPMjdMtjA2TDUzsEyzMqhItExJNjK2TDJJMzVKSTOxXMQq4puakpmTmZeq4JGamFOSoeCcWJQKADxqieNAAAAA&hl=en-GB&mat=CSS1mJ4GMDFRElMBeenfiLmIINMtbDv6FI4a-1lqx2JpiXfsAdVgJqxJ9RV3kCm7ARWL12Nx62ze-zZQoE_41fnaE3aFa-hMLBFrMZVJIpa0pR5yAZLnu_ygIbR-0w&authuser=0#ip=1">
                          <GrGoogle />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="NavSection">
          <div className="container-fluid">
            <Navbar
              collapseOnSelect
              expand="lg"
              variant="dark"
              className={color ? "NavBar NavBar-bg" : "NavBar"}
            >
              <Container>
                <Navbar.Brand href="/">
                  <img src={logo} alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="justify-content-start"
                >
                  <Nav>
                    <Nav.Link href="/">
                      <Link to="/">Home</Link>
                    </Nav.Link>
                    <Nav.Link href="/patient-caretaker-service">
                      <Link to="/patient-caretaker-service">About Us</Link>
                    </Nav.Link>
                    <button className="dropdownShow">
                      Service
                      <div className="dorpdown">
                        <div className="dropdown_list">
                          <Nav.Link href="/lab-test-home">
                            <Link to="/lab-test-home">Diagnostic</Link>
                          </Nav.Link>
                          <Nav.Link href="/home-visit-doctor">
                            <Link to="/home-visit-doctor">Procedure At Home</Link>
                          </Nav.Link>
                          <Nav.Link href="/home-visiting-doctors">
                            <Link to="/home-visiting-doctors">Home Visit</Link>
                          </Nav.Link>
                          <Nav.Link href="/medical-equipments">
                            <Link to="/medical-equipments">Pharmacy</Link>
                          </Nav.Link>
                          <Nav.Link href="/emergency-ambulance-service">
                            <Link to="/emergency-ambulance-service">Ambulance</Link>
                          </Nav.Link>
                        </div>
                      </div>
                    </button>
                    <Nav.Link href="/gallery">
                      <Link to="/gallery">Gallery</Link>
                    </Nav.Link>
                    <Nav.Link href="/contact-us">
                      <Link to="/contact-us">Contact Us</Link>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </section>
      </div>
    </>
  );
};

export default NavBar;
