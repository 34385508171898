import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Service from "./pages/Service";
import Gallery from './pages/Gallery';
import About from "./pages/About";
import Contact from "./pages/Contact";
import Diagnostic from "./pages/Diagnostic";
import Pharmacy from "./pages/Pharmacy";
import ProcedureHome from "./pages/ProcedureHome";
import Ambulance from "./pages/Ambulance";
import HomeVisit from "./pages/HomeVisit";
import CallBtn from './component/CallBtn';
import WhatsappBtn from './component/WhatsappBtn';
import Error from './component/Error';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <CallBtn/>
        <WhatsappBtn/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home-medical' element={<Service/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/patient-caretaker-service' element={<About/>}/>
          <Route path='/contact-us' element={<Contact/>}/>
          <Route path='/lab-test-home' element={<Diagnostic/>}/>
          <Route path='/medical-equipments' element={<Pharmacy/>}/>
          <Route path='/home-visit-doctor' element={<ProcedureHome/>}/>
          <Route path='/emergency-ambulance-service' element={<Ambulance/>}/>
          <Route path='/home-visiting-doctors' element={<HomeVisit/>}/>
          <Route path='*' element={<Error/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;