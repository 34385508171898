import React, { useState, useEffect } from "react";
import "../css/About.css";
import Footer from "../component/Footer.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoMdCheckboxOutline } from "react-icons/io";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CountUp from "react-countup";
import Button from "react-bootstrap/Button";
import Enquiry from "../component/Enquiry";
import { Link } from "react-router-dom";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import Heart from "../img/icons/heart.png";
import Doc from "../img/icons/doctor.png";
import Success from "../img/icons/success.png";
import Patient from "../img/icons/patient.png";
import Abouts from "../img/banner/abouts.webp";
import { MetaTags } from "react-meta-tags";
import NavBar from "../component/NavBar";

const About = () => {
  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      {/* ========= Sco ========== */}
      <MetaTags>
        <title>
          Expert Patient Caretaker Services in Mumbai | Mediline Health care
        </title>
        <meta
          name="description"
          content="Discover top-notch patient caretaker services in Mumbai offering trusted support and personalized care. Find compassionate caretakers today."
        />
        <meta
          name="google-site-verification"
          content="RsNmRSAUj8OUrThZ6RdzMd-KQnGfm_lGA-pXGuYhaqM"
        />
        <meta
          property="og:title"
          content="Expert Patient Caretaker Services in Mumbai | Mediline Health care"
        />
        <meta
          property="og:description"
          content="Discover top-notch patient caretaker services in Mumbai offering trusted support and personalized care. Find compassionate caretakers today."
        />
        <meta name="keywords" content="Patient Caretaker Services in Mumbai" />
        <link
          rel="canonical"
          href="https://medilinehomecare.in/patient-caretaker-service"
        />
      </MetaTags>
      {/* ======== Sco end ======= */}

      {/* ========== NavBar ========= */}
      <NavBar />
      {/* =============== Section1 =========== */}
      <div className="About-Section1">
        <div className="aboutBanner">
          <div className="aboutAnimation">
            <h1>
              We understand patient needs and{" "}
              <span className="d-none">
                Patient Caretaker Services in Mumbai
              </span>
              <br /> our caretaking service is flexible to .
            </h1>
          </div>
        </div>
      </div>
      {/* ============== Section2 ============ */}
      <div className="About-Section2 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="abouts_images">
                <div className="about-img">
                  <img src={Abouts} alt="blood test at home" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-heading">
                <h2>ABOUT MEDICAL</h2>
              </div>
              <div className="about-txt">
                <Zoom right>
                  <h3>
                    We understand patient needs and our caretaking service is
                    flexible to .
                  </h3>
                </Zoom>
                <p>
                  At Mediline, we put ourselves in the position of a person
                  whose family member is witnessing a health issue or undergoing
                  a curative treatment. In-depth understanding & empathising
                  with the entire pain & effort which goes into coordinating
                  with multiple healthcare providers at different stages of
                  recuperation - We launched a truly comprehensive 360° ONE-STOP
                  SHOP for all healthcare & medical needs to make ourselves your
                  Health Partners!
                </p>
                {/* <p>Our team works on preventive medicine along with the required medical treatment. We offer -</p> */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="about-list">
                    <ul>
                      <li>
                        <IoMdCheckboxOutline />
                        <span>
                          <a href="#">
                            <Link to="/Service">Nurse Provide</Link>
                          </a>
                        </span>
                      </li>
                      <li>
                        <IoMdCheckboxOutline />
                        <span>
                          <a href="#">
                            <Link to="/Service">Doctor at home service</Link>
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-list">
                    <ul>
                      <li>
                        <IoMdCheckboxOutline />
                        <span>
                          <a href="#">
                            <Link to="/Service">Ambulance Services</Link>
                          </a>
                        </span>
                      </li>
                      <li>
                        <IoMdCheckboxOutline />
                        <span>
                          <a href="#">
                            <Link to="/Service">Doctor at home service</Link>
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="about-heading">
                <h2>OUR MISSION</h2>
              </div>
              <div className="about-txt">
                <p>
                  Our core mission, aligned with the vision of our Founders,
                  doctors themselves, is to deliver affordable, high-quality,
                  and customized patient-centric care to their loved ones within
                  the comfort of their homes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============= Section3 ============= */}
      <div className="About-Section3 mt-5">
        <div className="container">
          <div className="row row-cols-md-4 row-cols-2">
            <div className="col">
              <diiv className="doc-exp">
                <div className="exp-icon">
                  <a href="#">
                    <img
                      src={Patient}
                      alt="blood test sample collection from home"
                    />
                  </a>
                </div>
                <div className="exp-count">
                  <CountUp end={200} duration={5} />
                  <h6>Satisfied Patients</h6>
                </div>
              </diiv>
            </div>
            <div className="col">
              <diiv className="doc-exp">
                <div className="exp-icon">
                  <a href="#">
                    <img src={Doc} alt="lab test at home" />
                  </a>
                </div>
                <div className="exp-count">
                  <CountUp end={200} duration={5} />
                  <h6>Expert Doctors</h6>
                </div>
              </diiv>
            </div>
            <div className="col">
              <diiv className="doc-exp">
                <div className="exp-icon">
                  <a href="#">
                    <img src={Success} alt="home blood sugar test" />
                  </a>
                </div>
                <div className="exp-count">
                  <CountUp end={200} duration={5} />
                  <h6>Hospitalizations Avoided</h6>
                </div>
              </diiv>
            </div>
            <div className="col">
              <diiv className="doc-exp">
                <div className="exp-icon">
                  <a href="#">
                    <img src={Heart} alt="blood test service at home" />
                  </a>
                </div>
                <div className="exp-count">
                  <CountUp end={200} duration={5} />
                  <h6>Medical Services</h6>
                </div>
              </diiv>
            </div>
          </div>
        </div>
      </div>
      {/* ============= Section5 ============= */}
      <div className="About-Section5 mt-5">
        <div className="testimonal-bg">
          <div className="container mt-5">
            <div className="testimonal-head">
              <h2>WHAT PATIENTS ARE SAYING</h2>
            </div>
            <div className="row">
              <div className="testimonal-bg">
                <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="Review"
                >
                  <SwiperSlide>
                    <div className="review">
                      <p>
                        Very good service and easy to approach , my mother
                        in-law was having breathing problem 2 days ago and I
                        called for their home service , help arrived in few
                        minutes and she was comfortable by morning , highly
                        recommend mediline
                      </p>
                      <h6>- Suvarna B</h6>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="review">
                      <p>
                        Very happy with the service..... ordered medicine late
                        night got great discounts - prompt service - highly
                        recommend it thank you keep up the good work
                      </p>
                      <h6>- ruchika K</h6>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="review">
                      <p>
                        Well trained Staff .... We contacted them for a blood
                        test ... The booking process and the home visit were
                        done smoothly ... especially technician Anil was very
                        good !! !! Highly recommend it !!!
                      </p>
                      <h6>- Ruchika K</h6>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============= About ============= */}
      <div className="enquiry mt-2">
        <div className="container">
          <Zoom>
            <h6>
              YOUR FIRST STEP TOWARDS EXPERIENCING ADVANCED MEDICINE STARTS
              HERE:
            </h6>
          </Zoom>
          <Bounce>
            <Button variant="enquiry_btn" onClick={() => setModalShow(true)}>
              Enquiry Now
            </Button>
          </Bounce>

          <Enquiry show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
